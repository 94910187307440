import { useState } from 'react';
import { useInitialize } from '../../generic/use';
import { Logger } from '../../generic/utils';
import { wrapperBuilder } from './build';
import { wrapperIdentifier } from './common';
import { Coinscrap } from './others/Coinscrap';
import { FacebookPixel } from './others/FacebookPixel';
import { GoogleAnalytics } from './others/GoogleAnalytics';
import { GoogleTagManager } from './others/GoogleTagManager';
import { Hotjar } from './others/Hotjar';
import { Teallium } from './others/Teallium';
import { TRANSPORTS } from './others/transportDefinitions';
import { WalmericDelio } from './others/WalmericDelio';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

wrapperBuilder
  .getCustomLogicRegisterInstance()
  .setLogic(
    ({
      bindedContexts: { user, getInstanceApi, loadSessionUser },
      componentProps,
    }) => {
      const [inited, setInited] = useState<boolean>(false);
      const transports = componentProps.transports || [
        { engine: TRANSPORTS.COINSCRAP },
      ];

      // console.log(`## 🥸 LOGIC USER`, user);

      const initialize = useInitialize(() => {
        for (let i = 0; i < transports.length; i++) {
          const transport = transports[i];
          transport.initProps.debugEnabled &&
            console.log('## ➕ INITING ' + transport.engine + ' Wrapper');
          switch (transport.engine) {
            case TRANSPORTS.COINSCRAP:
              Coinscrap.initialize(getInstanceApi, user, loadSessionUser, {
                ...transport.initProps,
                // debugEnabled
              });

              break;
            case TRANSPORTS.FACEBOOK_PIXEL:
              FacebookPixel.initialize(getInstanceApi, user, loadSessionUser, {
                ...transport.initProps,
                // debugEnabled
              });

              break;
            case TRANSPORTS.GOOGLE_ANALYTICS:
              GoogleAnalytics.initialize(
                getInstanceApi,
                user,
                loadSessionUser,
                {
                  ...transport.initProps,
                  // debugEnabled
                }
              );

              break;
            case TRANSPORTS.GOOGLE_TAG_MANAGER:
              GoogleTagManager.initialize(
                getInstanceApi,
                user,
                loadSessionUser,
                {
                  ...transport.initProps,
                  // debugEnabled
                }
              );

              break;
            case TRANSPORTS.HOTJAR:
              Hotjar.initialize(getInstanceApi, user, loadSessionUser, {
                ...transport.initProps,
                // debugEnabled
              });

              break;
            case TRANSPORTS.TEALLIUM:
              Teallium.initialize(getInstanceApi, user, loadSessionUser, {
                ...transport.initProps,
                // debugEnabled
              });

              break;
            case TRANSPORTS.WALMERIC_DELIO:
              WalmericDelio.initialize(getInstanceApi, user, loadSessionUser, {
                ...transport.initProps,
                // debugEnabled, useMainScript
              });

              break;
            default:
              throw new Error('Analytics transport not supported');
          }
        }
      }, false);

      if (user && !inited) {
        // console.log(`## 🥸 LOGIC INITIED`, inited);
        setInited(true);
        initialize();
      }
    }
  );
