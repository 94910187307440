import CircularProgress from '@material-ui/core/CircularProgress';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

import { ApplicationWrapper } from '../../../wrappers/ApplicationWrapper';
import { InAppBrowserWrapper } from '../../../wrappers/InAppBrowserWrapper';
import { TARGET_MODES } from '../../../wrappers/InAppBrowserWrapper/others';

const e = React.createElement;

export interface SimpleScanManagerProps {
  identityDocumentId: string;
  style: any;
  scanConfig: any;
  inNavigatorOptions: {
    text: string;
    toolbalcolor: string;
    closebuttonColor: string;
  };
}

//Este componente gestiona la creación del scan
export const SimpleScanManagerBuilder = (Wrapper: any) =>
  forwardRef(
    (
      {
        identityDocumentId,
        style,
        scanConfig,
        inNavigatorOptions,
      }: SimpleScanManagerProps,
      ref
    ) => {
      const { isIos } = ApplicationWrapper.use();
      const inappcontext = InAppBrowserWrapper.use();
      let open: any;
      if (inappcontext) {
        open = inappcontext?.open;
      }
      const { createScan } = Wrapper.use();

      const [currentScan, setCurrentScan] = useState<{
        id: string;
        data: { pwcs_url: string };
      }>();
      const [iframeLoading, setIsIframeLoading] = useState(true);

      const currentDniDocumentId = identityDocumentId;
      const currentScanId = currentScan?.id;
      // console.log('## currentScanId', currentScanId);
      // console.log('## currentDniDocumentId', currentDniDocumentId);
      // console.log('## iframeLoading', iframeLoading);
      const providerLoaded =
        currentScanId && currentDniDocumentId && !iframeLoading;
      // console.log('## providerLoaded', providerLoaded);

      const initScan = async () => {
        setIsIframeLoading(true);
        setCurrentScan(undefined);
        let scan = await createScan(identityDocumentId, scanConfig);
        setCurrentScan(scan);
      };

      useImperativeHandle(ref, () => ({
        reset() {
          initScan();
        },
      }));

      // Segun se carga el componente crea el scan
      useEffect(() => {
        if (identityDocumentId) {
          initScan();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [identityDocumentId]);

      useEffect(() => {
        // console.log('## inNavigatorOptions', inNavigatorOptions);
        // console.log('## open', open);
        if (
          inNavigatorOptions &&
          identityDocumentId &&
          currentScan?.id &&
          open
        ) {
          open(currentScan?.data?.pwcs_url, TARGET_MODES.BLANK, {
            closebuttoncaption: inNavigatorOptions.text,
            footer: false,
            hideurlbar: true,
            toolbarcolor: inNavigatorOptions.toolbalcolor ?? '#ffffff',
            closebuttoncolor: inNavigatorOptions.closebuttonColor ?? '#18b7cf',
            toolbarposition: 'top',
            ...(isIos ? { lefttoright: true } : {}),
          });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [inNavigatorOptions?.text, currentScan?.id, identityDocumentId]);

      // console.log('## DEBUG → providerLoaded', providerLoaded);
      // console.log('## DEBUG → identityDocumentId', identityDocumentId);
      // console.log('## DEBUG → currentScan', currentScan);

      return e(
        React.Fragment,
        {},
        !providerLoaded &&
          e(
            CircularProgress,
            {
              style: {
                marginTop: 10,
                marginBottom: 10,
                width: 40,
                height: 40,
              },
            },
            null
          ),
        identityDocumentId &&
          currentScan?.id &&
          e(
            'iframe',
            {
              style: {
                ...style,
              },
              id: 'scanIframe',
              frameBorder: '0',
              title: 'all-in-one-backend-scan',
              allow:
                'camera *;fullscreen *;geolocation *;gyroscope *;microphone *;speaker *',
              allowusermedia: 'true',
              onLoad: () => setIsIframeLoading(false),
              src: currentScan?.data?.pwcs_url,
            },
            null
          )
      );
    }
  );
