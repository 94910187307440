import { convertBase64ToBlob, Logger } from '../../generic/utils';
import {
  awsRegion,
  projectEnv,
  projectName,
} from '../../wrappers/ApplicationWrapper';
import { wrapperBuilder } from './build';
import { IdentityDocument, wrapperIdentifier } from './common';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

const functionsRegister = wrapperBuilder.getFunctionsRegisterInstance();

functionsRegister.addFunction(
  'loadUserIdentityDocuments',
  ({
    componentProps: { disableUrlsOverride },
    bindedContexts: { getInstanceApi },
    variablesHandler: { variablesSetter },
  }) => {
    const api = getInstanceApi();
    return async userId => {
      const dnis: IdentityDocument[] = await api.getUserIdentityDocuments(
        userId
      );
      variablesSetter('identityDocuments')(
        dnis.map(d => {
          d.filled = !!d.number;
          if (!disableUrlsOverride) {
            d.frontImage &&
              (d.frontImage =
                'https://s3.' +
                (awsRegion || 'eu-central-1') +
                '.amazonaws.com/com.coinscrap.' +
                projectName +
                `${projectEnv ? '.' + projectEnv : ''}` +
                '/uploads/dnis/images/' +
                d.frontImage);
            d.backImage &&
              (d.backImage =
                'https://s3.' +
                (awsRegion || 'eu-central-1') +
                '.amazonaws.com/com.coinscrap.' +
                projectName +
                `${projectEnv ? '.' + projectEnv : ''}` +
                '/uploads/dnis/images/' +
                d.backImage);
          }
          return d;
        })
      );
      /*contextModifier({
      testCompletions,
    })*/
    };
  }
);

functionsRegister.addFunction(
  'loadLastScanId',
  ({
    bindedContexts: { getInstanceApi },
    variablesHandler: { variablesSetter },
  }) => {
    const api = getInstanceApi();
    return async userId => {
      const dnis: IdentityDocument[] = await api.getUserIdentityDocumentsWithScans(
        userId
      );
      variablesSetter('lastMainScanId')(
        dnis.filter(dn => dn.main)?.[0]?.scans?.[0]?.id
      );
    };
  }
);

functionsRegister.addFunction(
  'addIdentityDocumentData',
  ({ coreDynamicFunctionGetter, bindedContexts: { getInstanceApi, user } }) => {
    const api = getInstanceApi();
    const loadUserIdentityDocuments = coreDynamicFunctionGetter(
      'loadUserIdentityDocuments'
    );

    return async (dniId, data) => {
      await api.uploadIdentityDocumentData(dniId, data);
      if (user) {
        await loadUserIdentityDocuments(user._id || user.id);
      }
    };
  }
);

functionsRegister.addFunction(
  'createScan',
  ({ bindedContexts: { getInstanceApi } }) => {
    const api = getInstanceApi();

    return async (dniId, scan) => {
      return await api.createScan(dniId, scan);
    };
  }
);

functionsRegister.addFunction(
  'updateScan',
  ({ coreDynamicFunctionGetter, bindedContexts: { getInstanceApi, user } }) => {
    const api = getInstanceApi();
    const loadUserIdentityDocuments = coreDynamicFunctionGetter(
      'loadUserIdentityDocuments'
    );
    const loadLastScanId = coreDynamicFunctionGetter('loadLastScanId');

    return async (dniId, scanId, method = 'PUT') => {
      const scan = await api.updateScan(dniId, scanId, method);
      if (user) {
        await loadUserIdentityDocuments(user._id || user.id);
        await loadLastScanId(user._id || user.id);
      }

      return scan;
    };
  }
);

functionsRegister.addFunction(
  'getBackendScanUrl',
  ({ bindedContexts: { getInstanceApi } }) => {
    return (dniId, scanId) => {
      const apiInstance = getInstanceApi();
      return {
        url: `${
          (apiInstance as any).baseUrlIds
        }/identity-documents/${dniId}/scans/${scanId}/backend-process`,
        userToken: apiInstance.token,
      };
    };
  }
);

functionsRegister.addFunction(
  'uploadIdentityDocumentImages',
  ({
    bindedContexts: { getInstanceApi },
    variablesHandler: { variablesSetter },
  }) => {
    const api = getInstanceApi();

    return async (dniId, sourceInBase64, front, back) => {
      if (!front && !back) return;

      let processedFront = front;
      let processedBack = back;

      console.log('ºº IMAGES RECEIVED', processedFront, processedBack);

      if (sourceInBase64) {
        if (processedFront) {
          processedFront = convertBase64ToBlob(processedFront);
        }
        if (processedBack) {
          processedBack = convertBase64ToBlob(processedBack);
        }
      }
      console.log('ºº IMAGES Blobed', processedFront, processedBack);
      await Promise.all([
        processedFront &&
          api.uploadIdentityDocumentImage(dniId, processedFront, 'front'),
        processedBack &&
          api.uploadIdentityDocumentImage(dniId, processedBack, 'back'),
      ]);
      variablesSetter('identityDocuments')(userIdentitys =>
        (userIdentitys || []).map(dni => {
          if (dni.id !== dniId) return dni;

          const dniModified = {
            ...dni,
          };
          processedFront &&
            (dniModified.frontImage =
              'https://s3.eu-central-1.amazonaws.com/com.coinscrap.' +
              projectName +
              '/uploads/dnis/images/' +
              dniModified.id +
              '_front');
          processedBack &&
            (dniModified.backImage =
              'https://s3.eu-central-1.amazonaws.com/com.coinscrap.' +
              projectName +
              '/uploads/dnis/images/' +
              dniModified.id +
              '_back');
          return dniModified;
        })
      );
    };
  }
);

functionsRegister.addFunction(
  'changeIdentityDocumentValidationMode',
  ({
    bindedContexts: { getInstanceApi },
    variablesHandler: { variablesSetter },
  }) => {
    const api = getInstanceApi();

    return async (dniId, manualMode) => {
      await api.changeIdentityDocumentValidationType(dniId, manualMode);
      variablesSetter('identityDocuments')(identityDocumentsOld =>
        (identityDocumentsOld || []).map(u => {
          if (u.id === dniId) {
            u.isManual = manualMode;
            if (manualMode) {
              u.version = null;
            }
          }
          return u;
        })
      );
    };
  }
);

functionsRegister.addFunction(
  'createIdentityDocument',
  ({
    bindedContexts: { getInstanceApi, user },
    variablesHandler: { variablesSetter },
  }) => {
    const api = getInstanceApi();
    return async (type, main) => {
      if (!user) {
        throw new Error('Not user in context');
      }
      const response = await api.createIdentityDocument(
        user._id || user.id,
        type,
        main
      );
      variablesSetter('identityDocuments')(identityDocumentsOld => [
        ...(identityDocumentsOld || []),
        {
          ...response,
          filled: false,
        },
      ]);
      return response;
    };
  }
);

functionsRegister.addFunction(
  'selectVersion',
  ({
    bindedContexts: { getInstanceApi },
    variablesHandler: { variablesSetter },
  }) => {
    const api = getInstanceApi();
    return async (dniId, version) => {
      await api.changeIdentityDocumentVersion(dniId, version);
      variablesSetter('identityDocuments')(identityDocumentsOld =>
        (identityDocumentsOld || []).map(u => {
          if (u.id === dniId) {
            u.version = version;
          }
          return u;
        })
      );
    };
  }
);

functionsRegister.addFunction(
  'setMainIdentityDocument',
  ({
    bindedContexts: { getInstanceApi },
    variablesHandler: { variablesSetter },
  }) => {
    const api = getInstanceApi();
    return async dniId => {
      await api.setMainIdentityDocument(dniId);
      variablesSetter('identityDocuments')(identityDocumentsOld =>
        (identityDocumentsOld || []).map(u => {
          if (u.id === dniId) {
            u.main = true;
          } else {
            u.main = false;
          }
          return u;
        })
      );
    };
  }
);
