import { useEffect } from 'react';
import { useInitializeWithFlag } from '../../generic/use';
import { Logger } from '../../generic/utils';
import { wrapperBuilder } from './build';
import { wrapperIdentifier } from './common';
import { Delio } from './others/Delio';
import { TagManager } from './others/TagManager';
import { Tealium } from './others/Tealium';
import { TRANSPORTS } from './others/transportDefinitions';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

wrapperBuilder
  .getCustomLogicRegisterInstance()
  .setLogic(({ componentProps, variablesHandler: { variablesSetter } }) => {
    const transports = componentProps.transports || [
      { engine: TRANSPORTS.GTM },
    ];

    const [inited] = useInitializeWithFlag(() => {
      console.log('## INITING ' + wrapperIdentifier + ' Wrapper');
      for (let i = 0; i < transports.length; i++) {
        const t = transports[i];
        switch (t.engine) {
          case TRANSPORTS.GTM:
            TagManager.initialize({
              ...t.initProps,
              // Id not needed, provided from env variables
              // gtmId: 'GTM-XXXXXX',
            });
            break;
          case TRANSPORTS.TEALIUM:
            Tealium.initialize({
              ...t.initProps,
              // Id not needed, provided from env variables
              // account, profile, environment
            });
            break;
          case TRANSPORTS.DELIO:
            Delio.initialize({
              ...t.initProps,
              // idTag, leadConfig, contactFormsConfig, scriptsConfig, eConversionMapper, eVisitMapper
            });
            break;
          default:
            throw new Error('Event tracker transport not supported');
        }
      }
    }, true);

    useEffect(() => {
      if (inited) {
        variablesSetter('inited')(true)
      }
    },[inited])
  });
