import i18n, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
import merge from 'lodash/merge';
import { Logger } from '../../../generic/utils';
const console = new Logger('internationalization');

if (console) {
  //Just for usage
}

export const i18nInit = (
  resources: Resource,
  initialLang?: string,
  options?: any
) => {
  const defaultOptions = {
    useLanguageDetector: true,
    nsSeparator: false,
    keySeparator: false,
    fallbackLng: undefined,
    debug: false,
    debugMissing: true,
    saveMissing: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      prefix: '%',
      suffix: '%',
    },
  };

  const i18nOptions = { ...defaultOptions, ...options };
  console.log('i18nOptions', i18nOptions);

  if (i18nOptions.useLanguageDetector) {
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    i18n.use(LanguageDetector);
  }

  return (
    i18n
      // pass the i18n instance to react-i18next.
      .use(initReactI18next)
      // init i18next
      // for all i18nOptions read: https://www.i18next.com/overview/configuration-i18nOptions
      .init({
        nsSeparator: i18nOptions.nsSeparator,
        keySeparator: i18nOptions.keySeparator,
        lng: initialLang,
        fallbackLng: i18nOptions.fallbackLng,

        debug: i18nOptions.debug,

        saveMissing: i18nOptions.saveMissing,
        missingKeyHandler: (_: any, ns: any, key: string, fall: any) => {
          const object = JSON.parse(
            localStorage.getItem('translation_resources') || '{}'
          );
          const newTrans = Object.keys(resources || {}).reduce(
            (acc, langKey) => {
              return {
                ...acc,
                [langKey]: {
                  ...(acc && acc[langKey]),
                  [ns]: {
                    ...(acc[langKey] && acc[langKey][ns]),
                    [key]:
                      (acc[langKey] &&
                        acc[langKey][ns] &&
                        acc[langKey][ns][key]) ||
                      fall,
                  },
                },
              };
            },
            merge(object, resources)
          );
          console.log('newTrans', newTrans);

          if (i18nOptions.debugMissing) {
            console.log('💥 NEW MISSING i18n translation. Key → ' + key);
          }
          localStorage.setItem(
            'translation_resources',
            JSON.stringify(newTrans)
          );
        },
        resources: resources,

        interpolation: i18nOptions.interpolation,
        react: {
          useSuspense: true,
        },
      })
  );
};
