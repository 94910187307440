import moment from 'moment';

import { Logger } from '../../../generic/utils';
import { commonFunctions, wrapperIdentifier } from '../common';
import { TRANSPORTS } from './transportDefinitions';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
var utag_data: any;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
var utag: any;

var inited = false;

export const Teallium = {
  initialize: async function(
    getInstanceApi: any,
    user: any,
    loadSessionUser: any,
    {
      debugEnabled,
    }: {
      debugEnabled?: boolean;
    }
  ) {
    if (!user) {
      inited = false;
      return;
    }
    if (!inited) {
      debugEnabled && console.log(`## 📣 ${TRANSPORTS.TEALLIUM} INITING`);
      inited = true;

      let analyticId = user?.metadata?.analytics?.TEALLIUM?.id;

      // SI NO TIENE LA ANALYTIC CREADA SE CREA
      if (!analyticId) {
        try {
          analyticId = await this.startAnalytic({
            getInstanceApi,
            user,
            debugEnabled,
          });
        } catch (error) {
          throw error;
        }
      }
      console.log(`## ✅ ${TRANSPORTS.TEALLIUM} analyticId`, analyticId);

      // SE AÑADEN LOS SCRIPTS
      try {
        const addedScripts = await this.addScripts({
          getInstanceApi,
          debugEnabled,
          analyticId,
        });
        debugEnabled &&
          console.log(
            `## ✅ ${TRANSPORTS.TEALLIUM} addedScripts`,
            addedScripts
          );
        if (analyticId) {
          await loadSessionUser();
        }
      } catch (error) {
        throw error;
      }
    }
  },
  startAnalytic: async function(args: { [k: string]: any }) {
    const { getInstanceApi, user, debugEnabled } = args;

    debugEnabled &&
      console.log(`## 📣 CREATE NEW ${TRANSPORTS.TEALLIUM} ANALYTICS`);

    try {
      const apiInstance = getInstanceApi();

      const transports = await apiInstance.microserviceCall('Analytics')(
        '/transports',
        'GET',
        {
          filter: { where: { identifier: TRANSPORTS.TEALLIUM } },
        }
      );

      if (transports.length === 0) {
        throw new Error('TEALLIUM is not actived');
      }

      const analyticsCreated = await apiInstance.microserviceCall('Analytics')(
        `/users/${user.id}/anltcs`,
        'POST',
        {
          transportId: transports[0].id,
          input: {},
        }
      );

      debugEnabled &&
        console.log(
          ` ${TRANSPORTS.TEALLIUM} analyticsCreated`,
          analyticsCreated
        );

      let analyticPooled: any = {};
      let status = analyticsCreated.status;
      let finishDate = moment()
        .add(20, 'seconds')
        .toDate();
      while (
        (status !== 'CREATED_SUCCESS' && status !== 'CREATED_ERROR') ||
        +new Date() > +finishDate
      ) {
        await new Promise(r => setTimeout(r, 1000));
        analyticPooled = await apiInstance.microserviceCall('Analytics')(
          `/anltcs/${analyticsCreated.id}`,
          'GET'
        );
        status = analyticPooled.status;
      }

      if (status === 'CREATED_ERROR') {
        debugEnabled &&
          console.log(
            `## ❌ ${TRANSPORTS.TEALLIUM} startAnalytic analyticPooled`,
            analyticPooled
          );
        throw new Error('Error creating analytic');
      } else if (status !== 'CREATED_SUCCESS') {
        debugEnabled &&
          console.log(
            `## ❌ ${TRANSPORTS.TEALLIUM} startAnalytic analyticPooled`,
            analyticPooled
          );
        throw new Error('Timeout reached creating analytic');
      }
      // debugEnabled &&
      //   console.log(
      //     `## ✅ ${TRANSPORTS.TEALLIUM} startAnalytic analyticPooled`,
      //     analyticPooled
      //   );

      return analyticsCreated.id;
    } catch (error) {
      debugEnabled && console.log('## 📌 error', error);
      throw error;
    }
  },
  addScripts: async function(args: { [k: string]: any }) {
    const { debugEnabled } = args;

    try {
      const scripts = await commonFunctions.getScripts(
        TRANSPORTS.TEALLIUM,
        args
      );
      debugEnabled &&
        console.log(`## 🧩 ${TRANSPORTS.TEALLIUM} scripts`, scripts);

      if (scripts.utagSyncScript) {
        const script = commonFunctions.srcScript(scripts.utagSyncScript);
        document.head.prepend(script);
      }
      if (scripts.utagScript) {
        const script = commonFunctions.dataScript(scripts.utagScript);
        document.body.prepend(script);
      }
      if (scripts.utagConfigScript) {
        const script = commonFunctions.dataScript(scripts.utagConfigScript);
        document.body.prepend(script);
      }
      if (scripts.utagDataScript) {
        const script = commonFunctions.dataScript(scripts.utagDataScript);
        document.body.prepend(script);
      }
      if (
        !scripts.utagSyncScript ||
        !scripts.utagScript ||
        !scripts.utagConfigScript
      ) {
        return false;
      }

      return true;
    } catch (error) {
      debugEnabled && console.log('## ❌ error', error);
      throw error;
    }
  },
  pageView: async function(
    getInstanceApi: any,
    user: any,
    payload: any,
    { debugEnabled }: { debugEnabled?: boolean }
  ) {
    debugEnabled &&
      console.log(`## 📝 ${TRANSPORTS.TEALLIUM} pageView`, payload);

    // TEALLIUM EVENT CONFIG
    const tealliumEvent = {
      page_type: payload.pageType,
      process_name: payload.processName,
      process_step: payload.processStep,
      process_type: payload.processType,
    };

    // WAIT FOR READY UTAG
    let cont = 0;
    let utagLoaded = false;

    try {
      utagLoaded = !!utag;
    } catch (e) {}

    while (!utagLoaded && cont < 3000) {
      try {
        utagLoaded = !!utag;
      } catch (e) {}

      await new Promise(res => setTimeout(res, 50));
      cont += 50;
    }

    const emitResult = await new Promise<void>((res, rej) => {
      try {
        if (utag) {
          utag.track('view', tealliumEvent, res);
        } else {
          throw new Error('No utag defined');
        }
      } catch (e) {
        rej(String(e));
      }
    });

    console.log('emitResult', emitResult);

    // REGISTER IN SERVICE-ANALYTICS
    try {
      const apiInstance = getInstanceApi();

      const eventDefinitions = await apiInstance.microserviceCall('Analytics')(
        '/event-definitions',
        'GET',
        {
          filter: {
            where: {
              identifier: `${TRANSPORTS.TEALLIUM}_EMIT_EVENT`,
              transportIdentifier: TRANSPORTS.TEALLIUM,
            },
          },
        }
      );

      if (eventDefinitions.length === 0) {
        throw new Error('EMIT_EVENT for TEALLIUM is not actived');
      }

      if (!user?.metadata?.analytics?.[TRANSPORTS.TEALLIUM]?.id) {
        let analyticInited: boolean = false;
        let id: string | undefined = undefined;
        let finishDate = moment()
          .add(10, 'seconds')
          .toDate();

        while (id === undefined || +new Date() > +finishDate) {
          await new Promise(r => setTimeout(r, 1000));
          analyticInited = user?.metadata?.analytics?.[TRANSPORTS.TEALLIUM]?.id
            ? true
            : false;
          id =
            user?.metadata?.analytics?.[TRANSPORTS.TEALLIUM]?.id ?? undefined;
        }

        if (!analyticInited || id === undefined) {
          debugEnabled &&
            console.log(`## ❌ ${TRANSPORTS.TEALLIUM} not inited`);
          throw new Error(`${TRANSPORTS.TEALLIUM} not inited`);
        }
      }

      const eventCreated = await apiInstance.microserviceCall('Analytics')(
        `/anltcs/${user.metadata.analytics[TRANSPORTS.TEALLIUM].id}/events`,
        'POST',
        {
          eventDefinitionId: eventDefinitions[0].id,
          input: { ...payload, emitResult },
        }
      );
      debugEnabled &&
        console.log(` ${TRANSPORTS.TEALLIUM} eventCreated`, eventCreated);

      let eventPooled: any = {};
      let status = eventCreated.status;
      let finishDate = moment()
        .add(20, 'seconds')
        .toDate();
      while (
        (status !== 'FINISHED_SUCCESS' && status !== 'FINISHED_ERROR') ||
        +new Date() > +finishDate
      ) {
        await new Promise(r => setTimeout(r, 1000));
        eventPooled = await apiInstance.microserviceCall('Analytics')(
          `/events/${eventCreated.id}`,
          'GET'
        );
        status = eventPooled.status;
      }

      if (status === 'FINISHED_ERROR') {
        debugEnabled &&
          console.log(
            `## ❌ ${TRANSPORTS.TEALLIUM} pageView eventPooled`,
            eventPooled
          );
        throw new Error('Error creating event');
      } else if (status !== 'FINISHED_SUCCESS') {
        debugEnabled &&
          console.log(
            `## ❌ ${TRANSPORTS.TEALLIUM} pageView eventPooled`,
            eventPooled
          );
        throw new Error('Timeout reached creating event');
      }
      debugEnabled &&
        console.log(
          `## ✅ ${TRANSPORTS.TEALLIUM} pageView eventPooled`,
          eventPooled
        );
      return { ...eventPooled.output };
    } catch (error) {
      debugEnabled && console.log('## 📌 error', error);
      throw error;
    }
  },
  emitEvent: async function(
    getInstanceApi: any,
    user: any,
    payload: any,
    { debugEnabled }: { debugEnabled?: boolean }
  ) {
    debugEnabled &&
      console.log(`## 📝 ${TRANSPORTS.TEALLIUM} emitEvent`, payload);

    // TEALLIUM EVENT CONFIG
    const tealliumEvent = { ...payload };

    // WAIT FOR READY UTAG
    let cont = 0;
    let utagLoaded = false;

    try {
      utagLoaded = !!utag;
    } catch (e) {}

    while (!utagLoaded && cont < 3000) {
      try {
        utagLoaded = !!utag;
      } catch (e) {}

      await new Promise(res => setTimeout(res, 50));
      cont += 50;
    }

    const emitResult = await new Promise<void>((res, rej) => {
      try {
        if (utag) {
          utag.track('link', tealliumEvent, res);
        } else {
          throw new Error('No utag defined');
        }
      } catch (e) {
        rej(String(e));
      }
    });

    console.log('emitResult', emitResult);

    // REGISTER IN SERVICE-ANALYTICS
    try {
      const apiInstance = getInstanceApi();

      const eventDefinitions = await apiInstance.microserviceCall('Analytics')(
        '/event-definitions',
        'GET',
        {
          filter: {
            where: {
              identifier: `${TRANSPORTS.TEALLIUM}_EMIT_EVENT`,
              transportIdentifier: TRANSPORTS.TEALLIUM,
            },
          },
        }
      );

      if (eventDefinitions.length === 0) {
        throw new Error('EMIT_EVENT for TEALLIUM is not actived');
      }

      if (!user?.metadata?.analytics?.[TRANSPORTS.TEALLIUM]?.id) {
        let analyticInited: boolean = false;
        let id: string | undefined = undefined;
        let finishDate = moment()
          .add(10, 'seconds')
          .toDate();

        while (id === undefined || +new Date() > +finishDate) {
          await new Promise(r => setTimeout(r, 1000));
          analyticInited = user?.metadata?.analytics?.[TRANSPORTS.TEALLIUM]?.id
            ? true
            : false;
          id =
            user?.metadata?.analytics?.[TRANSPORTS.TEALLIUM]?.id ?? undefined;
        }

        if (!analyticInited || id === undefined) {
          debugEnabled &&
            console.log(`## ❌ ${TRANSPORTS.TEALLIUM} not inited`);
          throw new Error(`${TRANSPORTS.TEALLIUM} not inited`);
        }
      }

      const eventCreated = await apiInstance.microserviceCall('Analytics')(
        `/anltcs/${user.metadata.analytics[TRANSPORTS.TEALLIUM].id}/events`,
        'POST',
        {
          eventDefinitionId: eventDefinitions[0].id,
          input: { ...payload, emitResult },
        }
      );
      debugEnabled &&
        console.log(` ${TRANSPORTS.TEALLIUM} eventCreated`, eventCreated);

      let eventPooled: any = {};
      let status = eventCreated.status;
      let finishDate = moment()
        .add(20, 'seconds')
        .toDate();
      while (
        (status !== 'FINISHED_SUCCESS' && status !== 'FINISHED_ERROR') ||
        +new Date() > +finishDate
      ) {
        await new Promise(r => setTimeout(r, 1000));
        eventPooled = await apiInstance.microserviceCall('Analytics')(
          `/events/${eventCreated.id}`,
          'GET'
        );
        status = eventPooled.status;
      }

      if (status === 'FINISHED_ERROR') {
        debugEnabled &&
          console.log(
            `## ❌ ${TRANSPORTS.TEALLIUM} emitEvent eventPooled`,
            eventPooled
          );
        throw new Error('Error creating event');
      } else if (status !== 'FINISHED_SUCCESS') {
        debugEnabled &&
          console.log(
            `## ❌ ${TRANSPORTS.TEALLIUM} emitEvent eventPooled`,
            eventPooled
          );
        throw new Error('Timeout reached creating event');
      }
      debugEnabled &&
        console.log(
          `## ✅ ${TRANSPORTS.TEALLIUM} emitEvent eventPooled`,
          eventPooled
        );
      return { ...eventPooled.output };
    } catch (error) {
      debugEnabled && console.log('## 📌 error', error);
      throw error;
    }
  },
  updateData: async function(
    getInstanceApi: any,
    user: any,
    payload: any,
    { debugEnabled }: { debugEnabled?: boolean }
  ) {
    // WAIT FOR READY UTAG
    let cont = 0;
    let utagLoaded = false;

    try {
      utagLoaded = !!utag;
    } catch (e) {}

    while (!utagLoaded && cont < 3000) {
      try {
        utagLoaded = !!utag;
      } catch (e) {}

      await new Promise(res => setTimeout(res, 50));
      cont += 50;
    }

    // UPDATE TEALLIUM DATA
    const tealiumPayload = { ...payload };
    delete tealiumPayload?.lead_config;

    debugEnabled &&
      console.log(`## 📈 ${TRANSPORTS.TEALLIUM} utag_data`, utag_data);

    let newData = {};
    if (utag_data) {
      newData = { ...utag_data, ...tealiumPayload };
    } else {
      newData = { ...tealiumPayload };
    }
    utag_data = newData;

    debugEnabled &&
      console.log(`## 📝 ${TRANSPORTS.TEALLIUM} newData`, newData);

    // REGISTER IN SERVICE-ANALYTICS
    try {
      const apiInstance = getInstanceApi();

      const eventDefinitions = await apiInstance.microserviceCall('Analytics')(
        '/event-definitions',
        'GET',
        {
          filter: {
            where: {
              identifier: `${TRANSPORTS.TEALLIUM}_UPDATE_DATA`,
              transportIdentifier: TRANSPORTS.TEALLIUM,
            },
          },
        }
      );

      if (eventDefinitions.length === 0) {
        throw new Error('UPDATE_DATA for TEALLIUM is not actived');
      }

      if (!user?.metadata?.analytics?.[TRANSPORTS.TEALLIUM]?.id) {
        let analyticInited: boolean = false;
        let id: string | undefined = undefined;
        let finishDate = moment()
          .add(10, 'seconds')
          .toDate();

        while (id === undefined || +new Date() > +finishDate) {
          await new Promise(r => setTimeout(r, 1000));
          analyticInited = user?.metadata?.analytics?.[TRANSPORTS.TEALLIUM]?.id
            ? true
            : false;
          id =
            user?.metadata?.analytics?.[TRANSPORTS.TEALLIUM]?.id ?? undefined;
        }

        if (!analyticInited || id === undefined) {
          debugEnabled &&
            console.log(`## ❌ ${TRANSPORTS.TEALLIUM} not inited`);
          throw new Error(`${TRANSPORTS.TEALLIUM} not inited`);
        }
      }

      const eventCreated = await apiInstance.microserviceCall('Analytics')(
        `/anltcs/${user.metadata.analytics[TRANSPORTS.TEALLIUM].id}/events`,
        'POST',
        {
          eventDefinitionId: eventDefinitions[0].id,
          input: {},
          // input: { ...newData }, https://github.com/Automattic/mongoose/issues/7144. Error
        }
      );
      debugEnabled &&
        console.log(` ${TRANSPORTS.TEALLIUM} eventCreated`, eventCreated);

      let eventPooled: any = {};
      let status = eventCreated.status;
      let finishDate = moment()
        .add(20, 'seconds')
        .toDate();
      while (
        (status !== 'FINISHED_SUCCESS' && status !== 'FINISHED_ERROR') ||
        +new Date() > +finishDate
      ) {
        await new Promise(r => setTimeout(r, 1000));
        eventPooled = await apiInstance.microserviceCall('Analytics')(
          `/events/${eventCreated.id}`,
          'GET'
        );
        status = eventPooled.status;
      }

      if (status === 'FINISHED_ERROR') {
        debugEnabled &&
          console.log(
            `## ❌ ${TRANSPORTS.TEALLIUM} updateData eventPooled`,
            eventPooled
          );
        throw new Error('Error creating event');
      } else if (status !== 'FINISHED_SUCCESS') {
        debugEnabled &&
          console.log(
            `## ❌ ${TRANSPORTS.TEALLIUM} updateData eventPooled`,
            eventPooled
          );
        throw new Error('Timeout reached creating event');
      }
      debugEnabled &&
        console.log(
          `## ✅ ${TRANSPORTS.TEALLIUM} updateData eventPooled`,
          eventPooled
        );
      return { ...eventPooled.output };
    } catch (error) {
      debugEnabled && console.log('## 📌 error', error);
      throw error;
    }
  },
};
