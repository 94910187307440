import React, { useContext, useState, useEffect, useRef } from 'react';
import { Logger } from './utils';

const console = new Logger('api');

if (console) {
  //Just for usage
}

//Consum the especified context
export const useProvider = <T>(
  provider: React.Context<T>,
  requester?: string
) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const providerInstance = useContext(provider);
  requester && console.log('Using provider from', requester, provider);
  return providerInstance;
};

//Wraps the logic to auto init a component. Returns a function thar triggers the initFunction prop
// This function its ussed to init logic that handles the component context. See BackendWrapper code for example
export const useInitialize = (initFunction: Function, autoInit: boolean) => {
  const [trigger, setTrigger] = useState<number>();
  const [inited, setInited] = useState<boolean>(false);
  function init() {
    setTrigger(Math.round(Math.random() * 9999999));
  }
  useEffect(() => {
    if (trigger && !inited) {
      setInited(true);
      initFunction();
    }
  }, [initFunction, trigger, inited]);

  useEffect(() => {
    if (autoInit) {
      init();
    }
  }, [autoInit]);
  return init;
};
export const useInitializeWithFlag = (initFunction: Function, autoInit: boolean) => {
  const [trigger, setTrigger] = useState<number>();
  const [initing, setIniting] = useState<boolean>(false);
  const [inited, setInited] = useState<boolean>(false);
  function init() {
    setTrigger(Math.round(Math.random() * 9999999));
  }
  useEffect(() => {
    if (trigger && !initing) {
      setIniting(true)
      initFunction();
      setInited(true)
    }
  }, [initFunction, trigger, initing]);

  useEffect(() => {
    if (autoInit) {
      init();
    }
  }, [autoInit]);
  return [inited, init] as const;
};


type Callback = () => void;

export const useInterval = (callback: Callback, delay: number | null) => {
  const savedCallback = useRef<Callback>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback && savedCallback.current && savedCallback.current();
    }
    let id: any;
    if (delay !== null) {
      id = setInterval(tick, delay);
    }
    return id ? () => clearInterval(id) : undefined;
  }, [delay]);
};
