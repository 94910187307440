import { wrapperBuilder } from './build';
import './functions';
import './logic';
import { ScanManagerBuilder } from './others/ScanManager';
import { SimpleScanManagerBuilder } from './others/SimpleScanManager';
import './variables';

export const IdentityDocumentsWrapper = wrapperBuilder.build();
IdentityDocumentsWrapper.ScanManager = ScanManagerBuilder(
  IdentityDocumentsWrapper
);
IdentityDocumentsWrapper.SimpleScanManager = SimpleScanManagerBuilder(
  IdentityDocumentsWrapper
);
