export const wrapperIdentifier = 'identityDocuments';

export interface IdentityDocument {
  id: string;
  number: string;
  isManual: boolean;
  main?: boolean;
  metdata?: { [k: string]: unknown };
  version?: string | null;
  filled?: boolean;
  frontImage?: string;
  backImage?: string;
  scans?: IdentityDocumentScan[];
}

export interface IdentityDocumentScan {
  id: string;
}
